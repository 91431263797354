import Link from 'next/link'
import { styled } from '@stitches/react'
import { useMediaQuery } from 'react-responsive'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'

import LoadingCard from 'components/common/LoadingCard'
import Card from 'components/custom/Card'
import { CDN_GATEWAY_URL } from 'utils/constants'
import { getKeyByContractId } from 'utils/collection'

function parseCollectionString(inputString: string) {
  const prefix = 'Pirate Nation - '
  const startIndex = inputString.indexOf(prefix)

  if (startIndex !== -1) {
    return inputString.substring(startIndex + prefix.length)
  } else {
    return inputString
  }
}

type Collection = {
  collection: ReturnType<typeof useCollections>['data']
}

type CollectionGridProps = {
  collections: Collection[]
}
// NOTE: Since we are accessing via two chains, can't fetch dynamic token media via contract and id
const ipfsTokenMedia = [
  'QmQYSdcxKKkWcSb2jMiii6nY5x67gsifRxZihMuSH3EEem/3950',
  'QmaxrVd4yAmyhrdAEnwmpABvCUxxscud3jKoqLWjSvCoXU',
  'QmahJfRA4e8EmDHLfqUny8ipSBxbH8w6Kh4hmEcvGcQkA5',
]

const GridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  '@media (max-width: 975px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  gap: '20px',
  width: '100%',
  height: '100%',
  paddingLeft: 0,
  boxSizing: 'border-box',
})

const CollectionGrid = ({ collections }: CollectionGridProps) => {
  return (
    <>
      <GridContainer>
        {collections.length > 0
          ? collections.map((collection: any, idx: number) => {
              const collectionName = parseCollectionString(collection?.name)

              return (
                <Link
                  key={`collection-${collection?.id}`}
                  href={`/nft/${getKeyByContractId(collection?.id)}`}
                >
                  <Card
                    imageIPFS={`${CDN_GATEWAY_URL}${ipfsTokenMedia[idx]}`}
                    title={collectionName}
                    numOfListings={Number(`${collection?.onSaleCount}`)}
                  />
                </Link>
              )
            })
          : Array(3)
              .fill(null)
              .map((_, index) => <LoadingCard key={`loading-card-${index}`} />)}
      </GridContainer>
    </>
  )
}

export default CollectionGrid
